import { CSSProperties } from 'react'
import { Space } from 'antd'

import { CusImage } from '@/components/CusImage'
import { toThousands } from '@/utils/utils'

/** 商品展示 */
export const ProductCell = (props: {
  style?: CSSProperties
  className?: string
  img: string | null | undefined
  sku?: string | null | undefined
  skuId?: string | number
  count?: number | null
  spu?: string | null
  price?: string | null
  title?: (string | null | undefined) | (string | null | undefined)[]
}) => {
  const { style, className, img, sku, skuId, count, spu, price, title } = props
  const titleList = [title].flat(1).filter(Boolean) as string[]

  return (
    <Space
      style={{ display: 'flex', overflow: 'hidden', wordBreak: 'break-all', ...style }}
      className={className}
      size={4}
    >
      <CusImage height={50} width={50} src={img || CusImage.defaultImg} />
      <Space style={{ lineHeight: 1.1 }} direction="vertical" size={4}>
        <div>
          {sku || skuId}
          {count !== undefined && count !== null && (
            <>
              *<span style={{ color: 'red' }}>{count}</span>
            </>
          )}
        </div>
        {!!spu && <div>{spu}</div>}
        {!!price && <div>{toThousands(price)}</div>}
        {!!titleList.length && <div>{titleList.join(' ')}</div>}
      </Space>
    </Space>
  )
}
