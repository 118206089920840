import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRequest } from 'ahooks'
import { Form, InputNumber, message, Modal, Spin } from 'antd'

import { toThousands } from '@/utils/utils'
import { apiCreateProcessOrder, apiGetProcessOrderFee } from './apis'
import { IRecord } from './interface'
import { popupSlot } from './utils'

interface IProcessOrderModalProps {
  record: IRecord
  onSuccess?: () => void
}

const updatePopup = popupSlot.insert(null)

export const ProcessOrderModal = Object.assign(
  ({ record, onSuccess, destroy }: IProcessOrderModalProps & { destroy: () => void }) => {
    const { t } = useTranslation()
    const { open, onHide, afterOpenChange } = popupSlot.useAntdPopupAnimation(destroy)
    const { skuId, sku, productEnTitle } = record

    const initialFormValues = useMemo(() => ({ nums: undefined as number | undefined }), [])
    const [form] = Form.useForm<{ nums: number | undefined }>()

    const [loading, setLoading] = useState(false)
    const {
      loading: feeLoading,
      data: fee,
      params: [param],
      run: getFee,
      mutate,
    } = useRequest((nums: number) => apiGetProcessOrderFee(skuId, nums), {
      manual: true,
      onBefore: () => void mutate(undefined),
    })

    const handleGetFee = () => {
      const { nums } = form.getFieldsValue()
      if (nums && nums !== param) getFee(nums)
    }

    return (
      <Modal
        title={t('LocalProductList.fa-qi-jia-gong-dan')}
        width={400}
        okButtonProps={{ loading }}
        onOk={async () => {
          if (feeLoading) return
          let values = await form.validateFields()
          values = form.getFieldsValue(true)
          if (!values.nums) return
          try {
            setLoading(true)
            await apiCreateProcessOrder(skuId, values.nums)
            message.success(t('2-common.cao-zuo-cheng-gong'))
            onHide()
            onSuccess?.()
          } finally {
            setLoading(false)
          }
        }}
        open={open}
        onCancel={onHide}
        afterOpenChange={afterOpenChange}
      >
        <Form form={form} labelCol={{ span: 10 }} initialValues={initialFormValues}>
          <Form.Item label="SKU">{sku}</Form.Item>
          <Form.Item label={t('LocalProductList.ying-wen-pin-ming')}>{productEnTitle}</Form.Item>
          <Form.Item label={t('LocalProductList.jia-gong-shu-liang')} name="nums" rules={[{ required: true }]}>
            <InputNumber
              style={{ width: 70 }}
              min={1}
              precision={0}
              onBlur={handleGetFee}
              onPressEnter={handleGetFee}
            />
          </Form.Item>
          <Form.Item label={t('LocalProductList.jia-gong-fei')}>{feeLoading ? <Spin /> : toThousands(fee)}</Form.Item>
        </Form>
      </Modal>
    )
  },
  {
    open: (props: IProcessOrderModalProps) => {
      updatePopup(<ProcessOrderModal {...props} destroy={() => updatePopup(null)} />)
    },
  },
)
