import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router'
import { useTranslation } from 'react-i18next'
import { Modal } from 'antd'

import { globalPopupSlot } from '../../globalPopupSlot'
import { IRecord } from '../../LocalProductList'
import { ILocalProductPickModalProps } from './interface'
import { LocalProductPick } from './LocalProductPick'

const updatePopup = globalPopupSlot.insert(null)

/** 本地产品选择弹窗组件 */
export const LocalProductPickModal = Object.assign(
  ({
    productType,
    shopProductInfo,
    disablePickSkuIds,
    disablePickBtnText,
    onPick,
    destroy,
  }: ILocalProductPickModalProps & { destroy: () => void }) => {
    const { t } = useTranslation()
    const { pathname } = useLocation()
    const initialPathname = useRef(pathname).current
    const { open, onHide, afterOpenChange } = globalPopupSlot.useAntdPopupAnimation(destroy)

    const handlePick = async ({ localProduct }: { localProduct: IRecord }) => {
      const result = onPick?.({ localProduct })
      if (result instanceof Promise) {
        const promiseVal = await result
        if (promiseVal !== false) onHide()
      } else {
        if (result !== false) onHide()
      }
    }

    useEffect(() => {
      if (initialPathname !== pathname) updatePopup(null)
    }, [initialPathname, pathname])

    return (
      <Modal
        title={t('LocalProductList.chan-pin-sou-suo')}
        width={900}
        footer={null}
        open={open}
        onCancel={onHide}
        afterOpenChange={afterOpenChange}
      >
        <LocalProductPick
          productType={productType}
          shopProductInfo={shopProductInfo}
          disablePickSkuIds={disablePickSkuIds}
          disablePickBtnText={disablePickBtnText}
          onPick={handlePick}
        />
      </Modal>
    )
  },
  {
    open: (props?: ILocalProductPickModalProps) => {
      updatePopup(<LocalProductPickModal {...props} destroy={() => updatePopup(null)} />)
    },
  },
)
