import { t } from 'i18next'
import { useTranslation } from 'react-i18next'
import { Button, InputNumber, Space } from 'antd'
import Icon from '@ant-design/icons'

import { getUnitText } from '../utils'
import WeightIcon from '@/assets/icons/weight.svg?react'
import WidthIcon from '@/assets/icons/width.svg?react'

export const SizeInput = Object.assign(
  ({
    unit,
    listLen,
    hideEmptyBtn,
    value,
    onChange,
    ...rest // 透传 Form.Item 附加的事件
  }: {
    unit: 1 | 2 | null | undefined
    /** 长度，长-宽-高-重 */
    listLen: 1 | 2 | 3 | 4
    hideEmptyBtn?: boolean
    value?: string
    onChange?: (value: string | undefined) => void
  }) => {
    const { t } = useTranslation()
    value = value?.replace(/[^\d.-]/g, '')

    let valueList: string[] = []
    if (value) {
      valueList = value.split('-').slice(0, listLen)
    }

    const { sizeUnit, weightUnit } = getUnitText(unit, 4)

    return (
      <Space>
        <Space split="-">
          {Array(listLen)
            .fill(null)
            .map((_v, i) => {
              let icon
              if (i === 0) icon = <Icon rotate={-45} component={WidthIcon} />
              if (i === 1) icon = <Icon component={WidthIcon} />
              if (i === 2) icon = <Icon rotate={90} component={WidthIcon} />
              if (i === 3) icon = <Icon component={WeightIcon} />
              return (
                <InputNumber
                  {...rest}
                  placeholder={i === 3 ? weightUnit : sizeUnit}
                  style={{ width: 120 }}
                  addonBefore={icon}
                  key={i}
                  min={1}
                  step={1}
                  value={valueList.length ? +valueList[i] || 0 : undefined}
                  onChange={val => {
                    const list = [...valueList]
                    list[i] = `${val || ''}`
                    if (`${list}`) onChange?.(list.join('-'))
                    else onChange?.(undefined)
                  }}
                />
              )
            })}
        </Space>
        {!!valueList.length && !hideEmptyBtn && (
          <Button type="text" onClick={() => onChange?.(undefined)}>
            {t('2-common.qing-kong')}
          </Button>
        )}
      </Space>
    )
  },
  {
    validate: async (value: string | undefined, listLen: 1 | 2 | 3 | 4): Promise<void> => {
      value = value?.replace(/[^\d.-]/g, '')
      let valueList: string[] = []
      if (value) {
        valueList = value.split('-').slice(0, listLen)
      }
      if (valueList.length) {
        for (const index of Array(listLen)
          .fill(null)
          .map((_v, i) => i)) {
          if (!+valueList[index]) {
            throw new Error(t('LocalProductList.qing-bu-qi-xin-xi'))
          }
        }
      }
    },
  },
)
