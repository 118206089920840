import { useMemo } from 'react'
import { t } from 'i18next'
import { useTranslation } from 'react-i18next'

import { createPopupSlot } from '@/services/popupSlot'

export const popupSlot = createPopupSlot()

/** 类目编码值 */
export const CATEGORY = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
] as const
/** 类目编码值:名称 */
export const useCATEGORY_NAME_MAP = (): Record<string, string | undefined> => {
  const { t } = useTranslation()
  return useMemo(
    () => ({
      1: t('LocalProductList.chong-wu-yong-pin'),
      2: t('LocalProductList.fu-shi-yu-pei-shi'),
      3: t('LocalProductList.yi-shu-yu-yu-le'),
      4: t('LocalProductList.ying-you-er-yong-pin'),
      5: t('LocalProductList.shang-ye-gong-ye'),
      6: t('LocalProductList.xiang-ji-yu-guang-xue-qi-jian'),
      7: t('LocalProductList.dian-zi-chan-pin'),
      8: t('LocalProductList.jia-ju'),
      9: t('LocalProductList.wu-jin-ying-jian'),
      10: t('LocalProductList.bao-jian-mei-rong-wei-sheng-hu-li'),
      11: t('LocalProductList.jia-ju-yu-yuan-yi'),
      12: t('LocalProductList.xiang-bao'),
      13: t('LocalProductList.cheng-ren'),
      14: t('LocalProductList.mei-ti'),
      15: t('LocalProductList.ban-gong-yong-pin'),
      16: t('LocalProductList.zong-jiao-yi-shi'),
      17: t('LocalProductList.ti-yu-yong-pin'),
      18: t('LocalProductList.wan-ju-you-xi'),
      19: t('LocalProductList.jiao-tong-gong-ju-qi-che-fei-ji-chuan-bo'),
    }),
    [t],
  )
}

/** 物流属性值 */
export const LOGISTICS_ATTRIBUTE = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17] as const
/** 物流属性值:名称 */
export const useLOGISTICS_ATTRIBUTE_NAME_MAP = (): Record<number, string | undefined> => {
  const { t } = useTranslation()
  return useMemo(
    () => ({
      1: t('LocalProductList.dai-ci'),
      2: t('LocalProductList.chun-dian'),
      3: t('LocalProductList.ye-ti'),
      4: t('LocalProductList.fen-mo'),
      5: t('LocalProductList.yi-sui'),
      6: t('LocalProductList.gao-ti'),
      7: t('LocalProductList.hua-zhuang-pin'),
      8: t('LocalProductList.fang-zhi-pin'),
      9: t('LocalProductList.nei-zhi-dian-chi-xiao-yu-100wh'),
      10: t('LocalProductList.nei-zhi-dian-chi-100wh'),
      11: t('LocalProductList.pei-tao-dian-chi-xiao-yu-100wh'),
      12: t('LocalProductList.pei-tao-dian-chi-100h'),
      13: t('LocalProductList.cheng-ren-yong-pin'),
      14: t('LocalProductList.fda-ren-zheng-chan-pin'),
      15: t('LocalProductList.yuan-mu'),
      16: t('LocalProductList.mu-zhi-pin'),
      17: t('LocalProductList.fu-zhuang-lei'),
    }),
    [t],
  )
}

/** 包材类型 */
export const PACKING_MATERIAL_TYPE = [1, 2, 3, 4, 5, 6] as const
/** 包材类型:名称 */
export const usePACKING_MATERIAL_TYPE_NAME_MAP = (): Record<number, string | undefined> => {
  const { t } = useTranslation()
  return useMemo(
    () => ({
      1: t('LocalProductList.fei-ji-he'),
      2: t('LocalProductList.ping-kou-xiang'),
      3: t('LocalProductList.huan-chong-dai'),
      4: t('LocalProductList.bu-gan-jiao'),
      5: t('LocalProductList.jiao-dai'),
      6: t('LocalProductList.chen-zhi'),
    }),
    [t],
  )
}

/** 尺寸单位值 */
export const SIZE_UNIT = [1, 2] as const
/** 尺寸单位值:名称 */
export const useSIZE_UNIT_NAME_MAP = (): Record<number, string | undefined> => {
  const { t } = useTranslation()
  return useMemo(
    () => ({
      1: t('LocalProductList.gong-zhi'),
      2: t('LocalProductList.ying-zhi'),
    }),
    [t],
  )
}

/** 包装类型值 */
export const WRAP_TYPE = [1, 2] as const
/** 包装类型值:名称 */
export const useWRAP_TYPE_NAME_MAP = (): Record<number, string | undefined> => {
  const { t } = useTranslation()
  return useMemo(
    () => ({
      1: t('LocalProductList.he-zhuang'),
      2: t('LocalProductList.dai-zhuang'),
    }),
    [t],
  )
}

/**
 * 获取规格尺寸单位文案
 * @param unit 1-公制(默认);2-英制;
 */
export const getUnitText = (unit: 1 | 2 | null | undefined, listLen: 1 | 2 | 3 | 4) => {
  const sizeUnit = unit === 2 ? t('LocalProductList.ying-cun') : t('LocalProductList.li-mi')
  const weightUnit = unit === 2 ? t('LocalProductList.ang-si') : t('LocalProductList.ke')
  const list = [
    `${t('LocalProductList.chang-du')}(${sizeUnit})`,
    `${t('LocalProductList.kuan-du')}(${sizeUnit})`,
    `${t('LocalProductList.gao-du')}(${sizeUnit})`,
    `${t('LocalProductList.zhong-liang')}(${weightUnit})`,
  ]
  return {
    sizeUnit,
    weightUnit,
    tip: list.slice(0, listLen).join(' - '),
  }
}
